import { template as template_cfd94734871342858993f3050ac5d2f6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_cfd94734871342858993f3050ac5d2f6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
