import { template as template_837d54a7bc9f4c70a1501f9a007bdaec } from "@ember/template-compiler";
const FKText = template_837d54a7bc9f4c70a1501f9a007bdaec(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
