import { template as template_14b1a71659994aca9f6165a4a53e334e } from "@ember/template-compiler";
const FKLabel = template_14b1a71659994aca9f6165a4a53e334e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
