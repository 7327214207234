import { template as template_91505df515a8479d8e65bc637266294a } from "@ember/template-compiler";
const WelcomeHeader = template_91505df515a8479d8e65bc637266294a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
