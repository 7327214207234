import { template as template_76d758cba99249248116d5a80c8fe406 } from "@ember/template-compiler";
const FKControlMenuContainer = template_76d758cba99249248116d5a80c8fe406(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
